.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 100;
  bottom: 7%;
  padding: 5px 20px;
  border-radius: 2px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  color: white;
  font-family: 'Futura Book';
  font-weight: 500;
  height: auto;
  max-width: 50%;
  background-color: #fe5000;
}

.message {
  background-color: #ffa000;
}

.containerLeft {
  left: 3%;
  bottom: 3%;
}

.containerRight {
  right: 1%;
}

.text {
  margin-bottom: 0;
  margin: 10px;
  font-family: 'Futura Book';
  font-weight: 500;
}

.icon {
  margin-left: 20px;
  cursor: pointer;
  background-color: #fe5000;
}

.message .icon {
  background-color: #ffa000;
}

@media (max-width: 1000px) {
  .container {
    bottom: 5%;
  }

  .containerLeft {
    left: 1%;
    right: 1%;
  }
}

@media (max-width: 768px) {
  .container {
    max-width: 100%;
  }
}