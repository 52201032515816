@import 'themes/default.css';

@font-face {
  font-family: 'Futura Book';
  src: url('assets/fonts/FuturaBook.ttf');
}

@font-face {
  font-family: 'Futura Bold';
  src: url('assets/fonts/FuturaBold.otf');
}

@font-face {
  font-family: 'Futura Medium';
  src: url('assets/fonts/FuturaMedium.otf');
}

@font-face {
  font-family: 'Futura Heavy';
  src: url('assets/fonts/FuturaHeavy.ttf');
}

@font-face {
  font-family: 'Futura Bold Italic';
  src: url('assets/fonts/FuturaBoldItalic.ttf');
}

@font-face {
  font-family: 'Futura Condensed Extra Bold Italic';
  src: url('assets/fonts/FuturaCondensedExtraBoldItalic.ttf');
}

@font-face {
  font-family: 'Futura Extra Bold';
  src: url('assets/fonts/FuturaExtraBold.ttf');
}

@font-face {
  font-family: 'Futura Bold Regular';
  src: url('assets/fonts/FuturaBoldRegular.ttf');
}

@font-face {
  font-family: 'Inter Italic';
  src: url('assets/fonts/Inter-Italic.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  src: url('assets/fonts/Montserrat-SemiBold.ttf');
}
@font-face {
  font-family: 'Montserrat Black';
  src: url('assets/fonts/Montserrat-Black.ttf');
}
@font-face {
  font-family: 'Montserrat Bold';
  src: url('assets/fonts/Montserrat-Bold.ttf');
}

@font-face {
  font-family: 'Montserrat-light';
  src: url('assets/fonts/Montserrat-Regular.ttf');
}

@font-face {
  font-family: 'Inter Thin BETA';
  src: url('assets/fonts/Inter-Thin-BETA.woff') format('woff');
}

@font-face {
  font-family: 'Roboto-Bold';
  src: url('assets/fonts/Roboto-Bold.ttf');
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  background-color: var(--white-smoke-color);
  --font-secondary-bold: 'Roboto-Bold', sans-serif;
}

button,
div,
textarea,
input,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: initial;
  -khtml-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
  user-select: initial;
}

a {
  color: black;
  text-decoration: none;
}

.react-html5-camera-photo > img,
.react-html5-camera-photo > video {
  width: inherit;
}

.react-multiple-carousel__arrow {
  z-index: 2;
}

.react-multi-carousel-dot-list {
  margin-bottom: 20px;
}

.react-multi-carousel-dot button {
  width: 8px;
  height: 8px;
  border: none;
}

.react-multi-carousel-dot--active button {
  background: #949494;
}

.react-multiple-carousel__arrow {
  background: rgba(225, 225, 225, 0.7);
}

.react-multiple-carousel__arrow--left {
  left: 0 !important;
}

.react-multiple-carousel__arrow--right {
  right: 0 !important;
}

.react-multiple-carousel__arrow::before {
  color: #949494;
}

.recharts-wrapper .recharts-cartesian-grid-horizontal line:first-child,
.recharts-wrapper .recharts-cartesian-grid-horizontal line:last-child {
  stroke-opacity: 0;
}

.recharts-wrapper .recharts-cartesian-grid-vertical line:first-child,
.recharts-wrapper .recharts-cartesian-grid-vertical line:last-child {
  stroke-opacity: 0;
}

#home_page-video-carousel .react-multi-carousel-dot-list {
  margin-bottom: 5px;
}

/* override slider material */

.MuiSlider-root {
  color: var(--orange-color) !important;
}

.MuiSlider-markActive,
.MuiSlider-mark {
  opacity: 1 !important;
  background-color: var(--orange-color) !important;
  width: 10px !important;
  height: 10px !important;
  top: 9px !important;
  border-radius: 7px !important;
}

.MuiSlider-markLabel {
  visibility: hidden;
  font-family: 'Futura Book' !important;
}

.MuiSlider-markLabelActive {
  visibility: visible;
  color: var(--grey-color) !important;
  margin-top: 10px;
}

.MuiSlider-thumb {
  width: 30px !important;
  height: 30px !important;
  top: 4px !important;
}

.MuiSlider-thumb::before {
  background: '';
  content: url('./assets/icons/fireIcon.svg');
  width: 18px;
}

.MuiSlider-thumb.Mui-focusVisible,
.MuiSlider-thumb:hover {
  box-shadow: none !important;
}

/* date picker */
.MuiInputBase-adornedEnd {
  background: white;
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  padding: 5px 15px;
  box-sizing: border-box;
  max-width: 100vw;

  font-family: 'Futura Book' !important;
  font-size: 16px;
}

.MuiInput-underline:before,
.MuiInput-underline:after {
  border-bottom: none !important;
}

.Mui-focused {
  border-bottom: none !important;
  border: 1px solid #000 !important;
}

.MuiPickersToolbar-toolbar,
.MuiPickersDay-daySelected {
  background-color: var(--orange-color) !important;
}

.MuiButton-textPrimary,
.MuiPickersDay-current {
  color: var(--orange-color) !important;
}

.MuiPickersDay-daySelected p {
  color: white !important;
}

.eatlove-scroll-wrapper {
  overflow-y: hidden;
}
