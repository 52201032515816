/* The container */
.container {
  display: block;
  position: relative;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-sizing: content-box;
  padding-left: 15px;
  padding-bottom: 15px;
  cursor: pointer;
  border-radius: 4px;
}

/* Hide the browser's default checkbox */
.container .input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  top: 0;
  left: 0;
}

/* Create a custom checkbox */
.checkmark {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  border-radius: 2px;
}

.orangeBorder {
  border: 2px solid var(--orange-color);
}

/* On mouse-over, add a grey background color */
.container:hover .input~.checkmark {
  background-color: #ffffff;
}

/* When the checkbox is checked, add a blue background */
.container .input:checked~.checkmark {
  background-color: var(--orange-color);
  border: 1px solid var(--orange-color);
}

.blackContainer .input:checked~.checkmark {
  background-color: #000;
  border: 1px solid #000;
}

.activeDisable .input:checked~.checkmark {
  background-color: var(--grey-color);
  border: 1px solid var(--grey-color);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container .input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 4px;
  top: 1px;
  width: 3px;
  height: 6px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}