.wrapper {
  box-sizing: border-box;
  margin: 8px 0 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 18px;
}

.blocksWrapper {
  display: flex;
}

.block {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #949494;
  margin-right: 5px;
  transition: background-color 0.25s cubic-bezier(0, 0, 1, 1);
}

.green {
  background-color: #00a640;
}

.greenText {
  color: #00a640;
}

.yellowText {
  color: #a89700;
}

.yellow {
  background-color: #a89700;
}

.red {
  background-color: #cc1919;
}

.redText {
  color: #cc1919;
}

.text {
  color: #000;
  padding-right: 6px;
}