.input {
  box-sizing: border-box;
  border: 1px solid #d8d8d8;
  background: #fff;
  width: 100%;
  height: 52px;
  padding: 15px 15px 7px 15px;
  border-radius: 10px;
  font-family: 'Montserrat-light';
  font-size: 16px;
  line-height: 16px;
  color: #000000;

  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.inputPadding {
  padding-left: 45px;
}

.inputWithRightIcon {
  padding-right: 45px;
}

.rightIcon {
  width: 15px;
  position: absolute;
  right: 25px;
  top: 19px;
}
.rightIconTextfield {
  width: 40px;
  position: absolute;
  right: 7px;
  top: 8px;
  cursor: pointer;
}

.input::placeholder {
  font-family: 'Montserrat-light';
  font-size: 16px;
  line-height: 21px;
  text-transform: uppercase;

  color: #000000;
  opacity: 0.7;
}

.input:hover {
  border: 1px solid #322e2e;
}

.input:focus {
  outline: none;

  border: none;
  border: 1px solid #000000;
}

.input:active {
  outline: none;
}

.invalid {
  border: 1px solid #cc0000;
}

.invalid:hover {
  border: 1px solid #cc0000;
}

.input:disabled {
}

.input:disabled:hover {
  border: 1px solid #d8d8d8;
}

.input:-webkit-autofill,
.input:-webkit-autofill:hover,
.input:-webkit-autofill:focus {
  -webkit-text-fill-color: transparent;
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
}

.input[hasvalue='true']:-webkit-autofill,
.input[hasvalue='true']:-webkit-autofill:hover,
.input[hasvalue='true']:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--primary-color);
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
}

.wrapper {
  position: relative;
}

.inputPassword {
  padding-right: 33px;
}

.inputIcon {
  width: 15px;
  position: absolute;
  left: 15px;
  top: 19px;
  filter: invert(0%) sepia(100%) saturate(0%) brightness(0%) contrast(100%);
}

.icon {
  position: absolute;
  bottom: 10px;
  right: 20px;
}

.icon:hover {
  cursor: pointer;
}

.wrapper1 {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
}

.formLabel {
  position: absolute;
  left: 17px;
  bottom: 15px;
  color: #999;
  transition: transform 150ms ease-out, font-size 150ms ease-out;

  font-family: 'Montserrat-light';
  font-size: 16px;
  line-height: 21px;

  color: #000000;
  opacity: 0.7;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.inputWithIcon {
  left: 45px;
}

.input:focus + .formLabel,
.input[hasvalue='true'] + .formLabel {
  transform: translateY(-60%);
  font-size: 10px;
  color: #939393;
  font-weight: 300;
  opacity: 0.7;
}
