:root {
  --orange-color: #fe5000;
  --black-color: #000000;
  --white-color: #ffffff;
  --grey-color: #949494;
  --grey-color-dark: #322e2e;
  --grey-color-light: #d8d8d8;
  --grey-color-background: #f5f5f5;
  --blue-color: #3959CD;
  --dark-blue-color: #364250;
  --black-color-background: #1f1f1f;
  --grey-color-text: #444444;
  --white-smoke-color: #f8f8f8;
  --whisper-color: #ececec;
  --red-color: #f44336;
  --green-color: #63C790;
}